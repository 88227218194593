import React, { useCallback, useState } from "react"
import styled from "styled-components"
import ImageContainer from "../../components/collection/ImageContainer"
import { useViewport } from "../../hooks/useViewport"
import ImageModal from "../Modal"

const Container = styled.div`
  margin-top: 130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 19px;
  display: grid;
  grid-template-columns: 342px 321px 321px;
  grid-template-rows: 323px 333px 324px 224px;
  column-gap: 100px;
  row-gap: 100px;
  width: min-content;
`
const Title = styled.div`
  font-family: "Chapaza";
  font-size: 60px;
  color: #212028;
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  letter-spacing: 2px;
`

const Text = styled.div`
  font-family: "Lato";
  font-size: 17px;
  color: #212028;
  max-width: 100%;
  max-height: 100%;
  grid-column: 1;
  grid-row: 2;
  @media (max-width: 768px) {
    margin: 10%;
  }
`

const MobileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Image = styled.img`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
  max-width: 100%;
  max-height: 100%;
  align-self: center;
  justify-self: center;
  object-fit: contain;

  @media (max-width: 768px) {
    max-width: 80%;
    max-height: 80%;
    margin: 5%;
  }
`

const List = ({ images }) => {
  const [modal, setModal] = useState({
    isOpen: false,
    image: "",
    caption: "",
  })

  const handleModalClose = useCallback(() => {
    setModal({ isOpen: false, image: "", caption: "" })
  }, [setModal])

  const { width } = useViewport()
  const breakpoint = 768

  return width < breakpoint ? (
    <MobileContainer>
      <Title>
        The
        <br /> Collection
      </Title>
      <Text>
        The Armstrong Project archives include over{" "}
        <span style={{ color: "#C84836" }}>18,000 photographs</span>,
        documenting a life that spanned from 1937 to 2016, a broadcasting and
        business career that began in 1954, a ten-year academic career, and
        especially a period of 50 years in politics. <br />
        <br />
        The latter collection includes thousands of pictures of constituents,
        meetings, campaign events, volunteers, staff, colleagues in the
        Legislature and Congress, and many national and world leaders.
        <br />
        <br />
        This small sampling will change frequently, so visit again soon, and
        often.
      </Text>
      {images &&
        images.map(image => {
          return <Image key={image.uid} src={image.data.image.url} />
        })}
    </MobileContainer>
  ) : (
    <>
      <ImageModal
        isOpen={modal.isOpen}
        image={modal.image}
        caption={modal.caption}
        onClose={handleModalClose}
      />

      <Container>
        <Title>
          The
          <br /> Collection
        </Title>
        <Text>
          The Armstrong Project archives include over{" "}
          <span style={{ color: "#C84836" }}>18,000 photographs</span>,
          documenting a life that spanned from 1937 to 2016, a broadcasting and
          business career that began in 1954, a ten-year academic career, and
          especially a period of 50 years in politics. <br />
          <br />
          The latter collection includes thousands of pictures of constituents,
          meetings, campaign events, volunteers, staff, colleagues in the
          Legislature and Congress, and many national and world leaders.
          <br />
          <br />
          This small sampling will change frequently, so visit again soon, and
          often.
          <br />
          <br />
          Hover over a picture to see the caption, click on it to enlarge
        </Text>

        {images &&
          images.map(image => {
            return (
              <ImageContainer
                key={image.uid}
                src={image.data.image.url}
                gridRow={image.data.gridrow}
                gridColumn={image.data.gridcolumn}
                caption={image.data.quote}
                onClick={() =>
                  setModal({ isOpen: true, image, caption: image.data.quote })
                }
              />
            )
          })}
      </Container>
    </>
  )
}

export default List
