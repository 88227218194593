import Prismic from "prismic-javascript"
import React, { useCallback, useEffect, useState } from "react"
import List from "../components/collection/List"
import Layout from "../components/layout"
import { client } from "../prismic/index"

const Collection = () => {
  const [results, setResults] = useState([])
  const getContent = useCallback(() => {
    client()
      .query([Prismic.Predicates.at("document.type", "images")])
      .then(response => {
        setResults(response.results)
      })
  }, [])

  useEffect(() => {
    getContent()
  }, [getContent])

  return (
    <Layout stacked={false}>
      <List images={results} />
    </Layout>
  )
}

export default Collection
