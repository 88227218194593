import PrismicDOM from "prismic-dom"
import React from "react"
import styled from "styled-components"

const Overlay = styled.div`
  height: 90%;
  width: 90%;
  opacity: 0;
  background-color: #ba503e;
  position: absolute;
  top: -10%;
  bottom: 0;
  left: -10%;
  right: 0;
  transition: 0.5s ease;

  .text {
    font-family: "Lato";
    font-size: 15px;
    letter-spacing: 1px;
    color: white;
    margin: 10% 10% 10% 10%;
    max-width: 75%;
  }
`

const Container = styled.div`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  align-self: center;
  justify-self: center;
  position: relative;

  ${Overlay}:hover {
    opacity: 0.9;
  }
`

const Image = styled.img`
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
  max-width: 100%;
  max-height: 100%;
  align-self: center;
  justify-self: center;
  object-fit: contain;

  @media (max-width: 768px) {
    max-width: 80%;
    max-height: 80%;
    margin: 5%;
  }
`

const ImageContainer = ({ onClick, gridRow, gridColumn, src, caption }) => (
  <Container onClick={onClick} gridRow={gridRow} gridColumn={gridColumn}>
    <Image src={src} />
    <Overlay>
      <div className="text">{PrismicDOM.RichText.asText(caption)}</div>
    </Overlay>
  </Container>
)

export default ImageContainer
